.containerStyle {
  text-align: center;
  padding-top: 70px; /* Adjust based on the size of your login button */
  position: relative; /* Ensure the button positions relative to this container */
}

.headingStyle {
  color: #D5B79F;
}

.paragraphStyle {
  font-size: 18px;
  color: #D5B79F;
}

.loginButtonStyle {
  position: absolute;
  top: 20px; /* Adjust if necessary */
  right: 20px;
}
