/* TODO make atom Login Button Styling */
.buttonStyle {
  padding: 10px 20px;
  background-color: #f1948a;
  color: white;
  border: none;
  border-radius: 8px; /* More rounded corners */
  cursor: pointer;
  z-index: 10; /* Ensure the button is above other content */
  font-weight: 500; /* Slightly bolder text */
  font-size: 16px; /* Adjust based on preference */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow for depth */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

.buttonStyle:hover {
  background-color: #f4b6c2; /* A slightly darker pastel pink for hover effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover for a "lifting" effect */
}
