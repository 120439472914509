/* Modal Backdrop Styles */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure it's above other content */
}

/* Modal Content Styles */
.modalContent {
  position: relative;
  width: 90%;
  max-width: 400px; /* Good default width for mobile devices */
  background-color: #fff;
  padding: 60px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Close Button Styles */
.closeButton {
  position: absolute;
  top: 12px; /* Adjust for better alignment */
  right: 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px;
  color: #707070; /* Subtle color, adjust as needed */
  width: 32px; /* Ensure a good clickable area */
  height: 32px; /* Ensure a good clickable area */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Circular shape for the button */
  transition: background-color 0.2s, color 0.2s; /* Smooth transition for hover effects */
}

.closeButton:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slight background color on hover for feedback */
  color: #000; /* Darker color for the icon on hover */
}

.closeButton:focus {
  outline: none; /* Remove outline for a cleaner look, ensure accessibility is maintained in other ways */
}

.closeButton:before {
  content: '\00d7'; /* Unicode character for '×' */
  font-size: 24px; /* Adjust as needed */
}

/* Input Fields Styling */
input[type="text"], input[type="password"] {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 24px); /* Adjust width accounting for padding */
  margin-bottom: 10px; /* Spacing between inputs */
}

/* TODO make atom Login Button Styling */
.loginButtonModal {
  padding: 12px 20px;
}

/* Responsive Design Adjustments */
@media (min-width: 768px) {
  .modalContent {
    width: 80%;
    max-width: 500px; /* Adjust as needed for tablet and up */
    padding: 60px 30px 30px;
  }
}

@media (min-width: 1024px) {
  .modalContent {
    width: 70%;
    max-width: 600px; /* Adjust for larger screens */
    padding: 40px; /* More padding for larger devices */
  }
}
