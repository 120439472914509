@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

.rbc-calendar .rbc-time-view .rbc-time-header.rbc-overflowing {
  margin-right: unset !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view .rbc-header {
  border-bottom: none;
}
